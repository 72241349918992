import { ThemeProvider } from '@material-ui/styles';
import moment from 'moment';
import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import App from './components/App';
import store from './store/index';
import theme from './styles/theme';
import 'moment/locale/es';
import './styles/main.sass';
const container = document.getElementById('root');
const root = createRoot(container);

moment.locale('es');

declare global {
  interface Window {
    _env_: any;
  }
}

root.render(
  <Provider store={store}>
    <ThemeProvider theme={theme}>
      <App />
    </ThemeProvider>
  </Provider>,
);
