import React, { ReactNode } from 'react';
import { useLang } from '../../contexts/lang.context';
import s from './NewsLayout.module.scss';

export type NewsLayoutProps = {
  children: ReactNode;
};
export default function NewsLayout(props: NewsLayoutProps) {
  const { setLang } = useLang();
  return (
    <div className={s['roa-layout']}>
      <div className={s['roa-layout-langs']}>
        <button onClick={() => setLang('IT')}>🇮🇹</button>
        <button onClick={() => setLang('EN')}>🇬🇧</button>
      </div>
      {props.children}
    </div>
  );
}
