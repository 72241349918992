import { createStyles, makeStyles } from '@material-ui/core';
import React from 'react';
import { Icon } from 'react-feather';
import Indicator from './Indicator';
import { RATING } from './utils';

const useStyles = makeStyles(() =>
  createStyles({
    sectionCard: {
      maxWidth: '250px',
      padding: '8px',
      margin: '10px auto 10px auto',
      backgroundColor: `#FCFCFD`,
      border: `1px solid #D0D5DD`,
      borderRadius: '5px',
    },
    sectionCardTitle: {
      display: 'flex',
      alignItems: 'center',
    },
    sectionCardIcon: {
      height: 32,
      width: 32,
      borderRadius: 6,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      marginRight: 12,
      backgroundColor: '#F9FAFB',
      border: `1px solid #344054`,
    },

    sectionCardMessage: {
      marginTop: '7px',
      fontSize: '12px',
    },
  }),
);

export type CardSectionProps = {
  percentage: number;
  icon: Icon;
  rating: RATING;
  title: string;
  message: string;
};
export default function CardSection(props: CardSectionProps) {
  const classes = useStyles();

  return (
    <div className={classes.sectionCard}>
      <div className={classes.sectionCardTitle}>
        <div className={classes.sectionCardIcon}>
          <props.icon size={20} color={'#344054'} />
        </div>
        <span
          style={{
            textAlign: 'left',
            fontWeight: 500,
            fontSize: 18,
          }}
        >
          {props.title}
        </span>
        <div
          style={{
            marginLeft: 'auto',
          }}
        >
          <Indicator
            rating={props.rating}
            theme={
              props.percentage > 0
                ? props.percentage === 100
                  ? 'SUCCESS'
                  : props.percentage < 25
                    ? 'ERROR'
                    : props.percentage < 50
                      ? 'ALERT'
                      : 'WARNING'
                : 'UNKNOWN'
            }
          />
        </div>
      </div>
      <div className={classes.sectionCardMessage}>{props.message}</div>
    </div>
  );
}
