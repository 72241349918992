import { Box } from '@material-ui/core';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import React from 'react';

const useStyles = makeStyles(() =>
  createStyles({
    content: {
      display: 'flex',
      alignItems: 'start',
      margin: 0,
      padding: 0,
    },
    iframe: {
      height: 'calc( 100vh - 50px )',
      width: '100%',
      border: 0,
      margin: 0,
      padding: 0,
    },
  }),
);
const Narratives = () => {
  const classes = useStyles();

  return (
    <Box className={classes.content}>
      <iframe className={classes.iframe} src='./narratives.html' />
    </Box>
  );
};

export default Narratives;
