import React from 'react';
import { LangProvider } from '../contexts/lang.context';
import { RealOrAIProvider } from '../contexts/realOrAi.context';
import NewsFooter from './NewsFooter/NewsFooter';
import NewsStack from './NewsStack';
import NewsStackMobile from './NewsStack/NewsStackMobile';

export default function RealOrAI() {
  return (
    <LangProvider>
      <RealOrAIProvider>
        <NewsStack />
        <NewsStackMobile />
        <NewsFooter />
      </RealOrAIProvider>
    </LangProvider>
  );
}
