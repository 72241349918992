import { combineReducers, createStore } from 'redux';
import { devToolsEnhancer } from 'redux-devtools-extension';
import { verifiable } from './reducers/verifiableReducer';

const appReducer = combineReducers({
  verifiable,
});

const store = createStore(appReducer, devToolsEnhancer({}));

export default store;

export type AppState = ReturnType<typeof appReducer>;
