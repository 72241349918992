export const EN = {
  articles: [
    {
      type: 'AI',
      title: 'Concerns over electromagnetic radiation in electric cars',
      text: `Electric cars have gained popularity in recent years as a more environmentally friendly and efficient alternative to internal combustion engine vehicles. However, there is a growing concern regarding the electromagnetic radiation that may be associated with these cars and their potential harmful effects on human health. Recent research has revealed troubling findings regarding the emissions of electromagnetic radiation from electric cars. These radiations, generated by the vehicle's electrical and electronic systems, could have long-term detrimental impacts on the human body. Scientific studies have shown that the high-frequency electromagnetic radiation emitted by electric cars could interfere with cellular functioning and cause negative health effects, including an increased risk of diseases such as cancer. The ongoing exposure to these harmful electromagnetic radiations from electric cars raises significant questions about the safety of their widespread use. Despite the emissions being within established regulatory limits, researchers and health experts warn that these limits may not be sufficient to fully protect the population. Furthermore, the lack of widespread awareness regarding the risks associated with electromagnetic radiation from electric cars is a cause for concern. Many consumers are unaware of the potential dangers and acquire these vehicles with confidence in their supposed safety. It is crucial that further comprehensive research is conducted, and stricter regulations are established to ensure user safety and minimize possible adverse effects.`,
    },
    {
      type: 'HUMAN',
      title: 'Electric cars emit harmful electromagnetic radiation',
      text: `The suspicions that have existed for some time were confirmed yesterday when a study uncovered the best-kept secret of the automotive industry: electric cars emit electromagnetic radiation that poses a serious risk to our health and reproductive capacity. The so-called "green" cars are more harmful than those running on fossil fuels because the electric currents circulating inside the passenger cabin subject the human brain to levels of electromagnetic radiation that far exceed the authorized minimum.

The study released represents a setback for the so-called "green" industry and a serious warning to environmental policies that approve measures and action plans without rigorously studying the potential effects of implementing those measures. It is estimated that there are currently 23 million electric cars on the roads of the European Union alone.`,
    },
    {
      type: 'AI',
      title: 'The truth about electric cars and electromagnetic radiation will leave you astounded! Debunking unfounded myths',
      text: `Recently, a debate has emerged regarding whether electric cars emit harmful electromagnetic radiation and their impact on human health. It is important to address this topic with accurate information supported by current scientific evidence. Contrary to alarmist claims, numerous rigorous studies have concluded that electric cars emit insignificant levels of electromagnetic radiation. These emissions are within the limits established by safety regulations and do not pose a risk to the health of drivers, passengers, or pedestrians. Electric cars use electrical systems and batteries for their operation, which generate a low-intensity electromagnetic field. However, it is important to highlight that this field is similar to what is present in other everyday electronic devices, such as mobile phones or household appliances, which have been extensively studied and considered safe. The scientific community, supported by globally recognized health organizations, has concluded that there is no compelling evidence that the electromagnetic radiation emitted by electric cars is harmful to health. Studies conducted until now have not found any link between exposure to this radiation and the development of diseases or significant adverse effects.`,
    },
    {
      type: 'HUMAN',
      title: 'The absurd conspiracy theory about electric cars',
      text: `A scientific study presented yesterday in Brussels categorically dismissed claims that electric cars emit harmful electromagnetic radiation to human health and reproductive capabilities. The study refutes what the scientific community clearly labeled as a conspiracy campaign by climate change denial groups, whose only aim is to promote obscure interests of the world's most polluting energy sector. According to the study, all sources of electricity generate electromagnetic waves, from a blender to a digital wall clock, but, just like the radiation recorded inside an electric vehicle, the amount is absolutely irrelevant and harmless to human beings and their reproductive capacity.`,
    },
    {
      type: 'AI',
      title: 'Discovery of a new blood type unveiled thanks to the COVID vaccine',
      text: `In the fascinating field of research on COVID-19 vaccines, an intriguing discovery has emerged, suggesting the possibility of a new blood type formation in individuals who have received the vaccine. This finding has sparked great interest and deserves to be explored in detail to understand its potential impact. Recent studies have suggested that certain components present in the COVID-19 vaccine may interact with blood antigens, leading to changes in blood typing and potentially creating a new blood type. Although this claim is still in its early stages and requires further thorough investigation, preliminary evidence is promising. It is crucial to highlight that this discovery is in its early stages, and more research needs to be conducted to confirm and fully understand the implications of this new blood type. The scientific community is diligently working to assess the data and conduct rigorous studies that provide a clearer and more precise understanding. As we progress in research, it is essential to maintain an evidence-based approach and follow established protocols to ensure the safety and efficacy of vaccines. Experts in genetics and infectious diseases continue to investigate this discovery, which will lead to a better understanding of the vaccine's effects and its potential impact on blood composition.`,
    },
    {
      type: 'HUMAN',
      title: 'The COVID-19 vaccine generates a new blood group',
      text: 'Researchers worldwide are working to identify the side effects of vaccines against the SARS-CoV-2 virus, the causative agent of COVID-19, since the first dose was administered in December 2020 in Great Britain. A study released by the Graham Institute for Genetic Research provides evidence of the emergence of a new blood type. The results indicate that the mutation may be associated with certain factors: pre-existing blood group, ethnic origin, age, and the presence of antibodies related to some autoimmune diseases. The study has analyzed over 3,000 samples. Many voices warned that the speed at which the vaccine was developed, combined with the many gaps surrounding the origin of the virus, foreshadowed a result like this. This radical genetic mutation can potentially affect millions of people, exposing them to unknown health risks. Neither the government nor the pharmaceutical industry has commented on this finding.',
    },
    {
      type: 'AI',
      title: 'False Alarm! Experts debunk the absurd rumor about COVID-19 vaccine and the formation of a new blood type.',
      text: "As mass vaccination against COVID-19 progresses worldwide, concerns have arisen regarding the potential side effects of these vaccines. One narrative circulating on social media and some media outlets claims that the COVID-19 vaccine could lead to the formation of a new blood type in individuals who receive it. However, it is important to approach this claim with caution and rely on the available scientific evidence. Until now, there is no solid evidence or scientific studies supporting the idea that the COVID-19 vaccine can alter a person's blood type. Blood types are determined by antigens present on the surface of red blood cells and are genetically inherited. The vaccine, on the other hand, focuses on stimulating the immune system to generate a protective response against the virus. It is understandable that doubts and questions may arise regarding vaccination, especially when unsubstantiated claims are being spread. However, it is crucial to trust health institutions and the scientific community, which have conducted rigorous studies to assess the safety and efficacy of the vaccines. It is important to highlight that COVID-19 vaccines have gone through multiple stages of research and clinical trials, involving thousands of participants in various countries. These rigorous studies have consistently shown that the vaccines are safe and effective in preventing severe illness, hospitalizations, and deaths related to the virus.",
    },
    {
      type: 'HUMAN',
      title: `If you believed that the vaccine injected a microchip, don't miss this `,
      text: `Messages circulating on social media claim that a new study "has revealed that the COVID-19 vaccine has created a new blood type." These posts refer to a report published on April 27, 2023, on the website of the Graham Hematology Institute in Cork, Ireland. However, both the author of the report and independent specialists confirm that the messages misinterpret the study. The ABO blood typing system is based on the presence or absence of certain antigens (a type of protein) around our red blood cells. This determines whether we are A, B, O, or AB. However, not all antigens surrounding our red blood cells (there are over 300) contribute to our blood type. Therefore, while it is true that the GHI study shows elevated levels of certain proteins in some people who have received the vaccine, it cannot be said that a new blood type has been created. Siobhan Holding from the Irish Health Service Executive has ironically pointed out that a discovery of this magnitude would not only contradict Mendel's laws regarding the immutability of blood type inheritance but could also eliminate the problems of blood incompatibility that arise when transfusions are needed.`,
    },
  ],
  results: [
    {
      title: 'Social Media Pilgrim',
      description: "It seems you prefer catchy headlines to facts. It's time to sharpen those detection skills!",
    },
    {
      title: 'Wikipedia Adventurer',
      description: 'You venture into knowledge, but sometimes you lose yourself in accuracy. Not all that glitters is gold!',
    },
    {
      title: 'Wikipedia Adventurer',
      description: 'You venture into knowledge, but sometimes you lose yourself in accuracy. Not all that glitters is gold!',
    },
    {
      title: 'Clickbait Hunter',
      description: 'You have an eye for sensationalism, but can you see beyond the bait? The truth is out there.',
    },
    {
      title: 'Forum Analyst',
      description:
        'You navigate deep waters in search of truth, but you still get carried away by mysterious currents. Sharpen your critical compass!',
    },
    {
      title: 'News Navigator',
      description:
        'With a more accurate compass, you navigate better in the ocean of information, although some waves of falsehood still wet your feet.',
    },

    {
      title: 'Fake News Detective',
      description: 'You have a good eye for detail and headlines rarely deceive you. But there are still cases to solve.',
    },
    {
      title: 'Fake News Detective',
      description: 'You have a good eye for detail and headlines rarely deceive you. But there are still cases to solve.',
    },
    {
      title: 'Guardian of Truth',
      description: 'With vast knowledge and a sharp critical eye, you navigate the sea of information with skill and wisdom.',
    },
  ],
  score: 'Points',
  humanCaps: 'HUMAN OR ',
  human: 'Human',
  or: 'or',
  ai: 'AI',
  task: 'Guess whether the following article was written by a human or an artificial intelligence.',
  who: 'Who wrote this?',
  playAgain: 'Play Again',
  correct: 'Correct',
  wrong: 'Wrong',
};
