import { Color } from '@material-ui/lab/Alert';
import { History } from 'history';
import React, { useEffect, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { Router, Route, Switch } from 'react-router-dom';
import { setToken } from '../store/actions/verifiableActions';
import Narratives from './Narratives/Narratives';
import RealOrAI from './RealOrAI';
import URLAnalysis from './URLAnalysis/URLAnalysis';
import VerifiableText from './VerifiableText';
import VerifiableTweet from './VerifiableTweet';

interface RecaptchaWrapperProps {
  showAlert: (text: string, color: Color) => void;
  history: History;
}

const RecaptchaWrapper = ({ history, showAlert }: RecaptchaWrapperProps) => {
  const dispatch = useDispatch();

  const recaptchaToken = useCallback(
    (type: string) => {
      window.grecaptcha.ready(() => {
        window.grecaptcha.execute(window._env_.REACT_APP_CAPTCHA_KEY, { action: 'validate_captcha' }).then((token: string) => {
          dispatch(setToken({ value: token, type }));
        });
      });
    },
    [dispatch],
  );

  const resetToken = () => {
    dispatch(setToken(null));
  };

  useEffect(() => {
    const script = document.createElement('script');
    script.src = `${window._env_.REACT_APP_CAPTCHA_LIB}?render=${window._env_.REACT_APP_CAPTCHA_KEY}`;
    document.body.appendChild(script);
  }, []);

  return (
    <Router history={history}>
      <Switch>
        <Route
          exact
          path={['/', '/content-score']}
          render={() => <URLAnalysis recaptchaToken={recaptchaToken} resetToken={resetToken} showAlert={showAlert} />}
        ></Route>
        <Route exact path='/narratives' render={() => <Narratives />}></Route>
        <Route
          exact
          path='/tweet'
          render={() => <VerifiableTweet recaptchaToken={recaptchaToken} resetToken={resetToken} showAlert={showAlert} />}
        ></Route>
        <Route
          exact
          path='/text'
          render={() => <VerifiableText recaptchaToken={recaptchaToken} resetToken={resetToken} showAlert={showAlert} />}
        ></Route>
        <Route exact path={'/umano-ia'} render={() => <RealOrAI />}></Route>
      </Switch>
    </Router>
  );
};
export default RecaptchaWrapper;
