import { Box, Button, Divider, ListItem, ListItemIcon, ListItemText, Tooltip, createStyles, makeStyles } from '@material-ui/core';
import { Check, Error } from '@material-ui/icons';

import React from 'react';
import { ResponseURLAnalysisInterface, SentenceSubjectivity, SentenceToxicity } from '../../api/interfaces';
const useStyles = makeStyles(() =>
  createStyles({
    item_icon_box_not: {
      minWidth: '50px',
      width: '50px',
      fontSize: '17px',
      display: 'flex',
      justifyContent: 'center',
      backgroundColor: 'rgb(50, 213, 131)',
      marginLeft: '5px',
      marginRight: '5px',
      borderRadius: '8px',
      boxShadow: '0 8px 17px 0 rgba(0,0,0,0.1), 0 2px 1px 0 rgba(0,0,0,0.12)',
      '&:hover': {
        backgroundColor: 'rgb(50, 213, 131, 0.8)',
      },
    },
    item_icon_box_yes: {
      minWidth: '50px',
      width: '50px',
      fontSize: '17px',
      display: 'flex',
      justifyContent: 'center',
      backgroundColor: 'rgb(240, 68, 56)',
      marginLeft: '5px',
      marginRight: '5px',
      borderRadius: '8px',
      boxShadow: '0 8px 17px 0 rgba(0,0,0,0.1), 0 2px 1px 0 rgba(0,0,0,0.12)',
      '&:hover': {
        backgroundColor: 'rgb(240, 68, 56, 0.8)',
      },
    },
    item_icon_box_disabled: {
      minWidth: '50px',
      width: '50px',
      fontSize: '17px',
      display: 'flex',
      justifyContent: 'center',
      backgroundColor: 'transparent',
      marginLeft: '5px',
      marginRight: '5px',
      borderRadius: '12px',
      cursor: 'default',
      '&:hover': {
        backgroundColor: 'transparent',
      },
    },
    item_sentence_box: { width: '100%', paddingLeft: '20px', fontSize: '17px' },
  }),
);

export type SentenceProps = {
  urlAnalysisResults: ResponseURLAnalysisInterface;
  sentenceMetrics: SentenceSubjectivity | SentenceToxicity;
  editMode: boolean;
  sendFeedback: (sentenceId?: number, toxic?: boolean, subjective?: boolean, contentRating?: string) => void;
  setEditModeURLAnalysisResults: (urlAnalysisResults: ResponseURLAnalysisInterface) => void;
};

export default function Sentence(props: SentenceProps) {
  const classes = useStyles();

  const urlAnalysisResults = props.urlAnalysisResults;
  const sentenceToxicity = urlAnalysisResults?.sentences_toxicity?.find(sentence => sentence.id === props.sentenceMetrics.id);
  const sentenceSubjectivity = urlAnalysisResults?.sentences_subjectivity?.find(sentence => sentence.id === props.sentenceMetrics.id);
  const isSentenceToxic = sentenceToxicity?.toxicity === undefined || sentenceToxicity?.toxicity !== 'no toxic';
  const isSentenceSubjective = sentenceSubjectivity?.subjectivity === undefined || sentenceSubjectivity?.subjectivity !== 'no subjective';
  const sendSentenceFeedbackSubjectivity = () => {
    if (props.editMode) {
      props.sendFeedback(sentenceSubjectivity?.id, isSentenceToxic, !isSentenceSubjective);
      props.setEditModeURLAnalysisResults({
        ...urlAnalysisResults,
        sentences_subjectivity: urlAnalysisResults.sentences_subjectivity.map(sentence =>
          sentence.id === sentenceSubjectivity.id
            ? { ...sentence, subjectivity: isSentenceSubjective ? 'no subjective' : 'subjective' }
            : sentence,
        ),
      });
    }
  };
  const sendSentenceFeedbackToxicity = () => {
    if (props.editMode) {
      props.sendFeedback(sentenceToxicity?.id, !isSentenceToxic, isSentenceSubjective);
      props.setEditModeURLAnalysisResults({
        ...urlAnalysisResults,
        sentences_toxicity: urlAnalysisResults.sentences_toxicity.map(sentence =>
          sentence.id === sentenceToxicity.id ? { ...sentence, toxicity: isSentenceToxic ? 'no toxic' : 'toxic' } : sentence,
        ),
      });
    }
  };

  return (
    <div key={props.sentenceMetrics.id}>
      <ListItem>
        <Divider orientation='vertical' flexItem />
        <Box>
          <Button
            className={
              !props.editMode
                ? classes.item_icon_box_disabled
                : !isSentenceSubjective
                  ? classes.item_icon_box_not
                  : classes.item_icon_box_yes
            }
            disableRipple
            onClick={() => {
              sendSentenceFeedbackSubjectivity();
            }}
          >
            <ListItemIcon style={{ justifyContent: 'center' }}>
              {!isSentenceSubjective && (
                <Tooltip title={props.editMode ? 'Change to subjective' : 'Not subjective'}>
                  <Check style={{ color: props.editMode ? 'white' : '#32D583' }} />
                </Tooltip>
              )}
              {isSentenceSubjective && (
                <Tooltip title={props.editMode ? 'Change to not subjective' : 'Subjective'}>
                  <Error style={{ color: props.editMode ? 'white' : '#F04438' }} />
                </Tooltip>
              )}
            </ListItemIcon>
          </Button>
        </Box>
        <Divider orientation='vertical' flexItem />

        <Button
          className={
            !props.editMode ? classes.item_icon_box_disabled : !isSentenceToxic ? classes.item_icon_box_not : classes.item_icon_box_yes
          }
          disableRipple
          onClick={() => {
            sendSentenceFeedbackToxicity();
          }}
        >
          <ListItemIcon style={{ justifyContent: 'center' }}>
            {!isSentenceToxic && (
              <Tooltip title={props.editMode ? 'Change to toxic' : 'Not toxic'}>
                <Check style={{ color: props.editMode ? 'white' : '#32D583' }} />
              </Tooltip>
            )}
            {isSentenceToxic && (
              <Tooltip title={props.editMode ? 'Change not toxic' : 'Toxic'}>
                <Error style={{ color: props.editMode ? 'white' : '#F04438' }} />
              </Tooltip>
            )}
          </ListItemIcon>
        </Button>
        <Divider orientation='vertical' flexItem />

        <Box className={classes.item_sentence_box}>
          <ListItemText primary={props.sentenceMetrics.sentence} />
        </Box>
        <Divider orientation='vertical' flexItem />
      </ListItem>
      <Divider />
    </div>
  );
}
