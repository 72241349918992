import { Box } from '@material-ui/core';
import AppBar from '@material-ui/core/AppBar';
import IconButton from '@material-ui/core/IconButton';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import MenuIcon from '@material-ui/icons/Menu';
import clsx from 'clsx';
import { History } from 'history';
import React, { useState } from 'react';
import logolg from '../styles/icons/newtral_labs_logo.svg';
import logosm from '../styles/icons/newtral_labs_logo_icon.svg';
import DrawerRight from './DrawerRight';

const drawerWidth = 240;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
    },
    appBar: {
      transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
    appBarShift: {
      width: `calc(100% - ${drawerWidth}px)`,
      transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginRight: drawerWidth,
    },
    hide: {
      display: 'none',
    },
    menu: {
      justifyContent: 'space-between',
      paddingRight: '10px',
      color: 'white',
      backgroundColor: 'black',
    },
    menuIcon: {
      fontSize: '30px',
    },
    title: {
      flexGrow: 1,
      paddingLeft: '10px',
      fontSize: '22px',
      fontWeight: 500,
      marginLeft: '5px',
      borderLeft: '1px solid #fff',
      cursor: 'pointer',
    },
  }),
);

interface HeaderProps {
  history: History;
}

const Header = ({ history }: HeaderProps) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const goToPath = (path: string) => {
    history.push(path);
    handleDrawerClose();
  };

  return (
    <Box className={classes.root}>
      <AppBar
        position='fixed'
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open,
        })}
      >
        <Toolbar className={classes.menu}>
          <img className='logo logo-lg' src={logolg} alt='Newtral Labs' onClick={() => goToPath('/')} />
          <img className='logo logo-sm' src={logosm} alt='Newtral Labs' onClick={() => goToPath('/')} />
          <IconButton color='inherit' aria-label='open drawer' edge='end' onClick={handleDrawerOpen} className={clsx(open && classes.hide)}>
            <MenuIcon className={classes.menuIcon} />
          </IconButton>
        </Toolbar>
      </AppBar>
      <DrawerRight handleDrawerOpen={handleDrawerOpen} handleDrawerClose={handleDrawerClose} open={open} goToPath={goToPath} />
    </Box>
  );
};
export default Header;
