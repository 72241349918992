import React, { useEffect, ReactNode, createContext, useContext, useState } from 'react';
import { EN, IT } from '../locales';

export type LANG = 'IT' | 'EN';
export type LangContextProps = {
  lang: LANG;
  setLang: (l: LANG) => void;
  t: (k: string) => any;
};
export const LangContext = createContext<LangContextProps>({
  lang: 'EN',
  t: () => {},
  setLang: () => {},
});

export type LangProviderProps = {
  children: ReactNode;
};
export const LangProvider = (props: LangProviderProps) => {
  const [lang, setLang] = useState<LANG>('IT');
  const [t, setT] = useState<any>({});

  const locales: Record<LANG, any> = {
    EN,
    IT,
  };

  useEffect(() => {
    setLang('IT');
  }, []);

  useEffect(() => {
    setT(locales[lang]);
  }, [lang]);

  const parseLocales = (key: string) => {
    return t?.[key];
  };

  const context = { lang, t: parseLocales, setLang };
  return <LangContext.Provider value={context}>{props.children}</LangContext.Provider>;
};

export const useLang = () => useContext(LangContext);
