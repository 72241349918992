import moment from 'moment';
import React from 'react';
import { useLang } from '../../contexts/lang.context';
import { useRealOrAi } from '../../contexts/realOrAi.context';
import s from './NewsPage.module.scss';

export default function NewsPageHeader() {
  const { points, length } = useRealOrAi();
  const { t } = useLang();
  return (
    <div className={s['roa-page-header']}>
      <div className={s['roa-page-header-t']}>
        <div />
        <span>{moment().format('DD/MM/YYYY')}</span>
        <span>
          {t('score')}:{' '}
          <b>
            {points}/{length}
          </b>
        </span>
      </div>
      <h1 className={s['roa-page-header-b']}>
        {t('humanCaps')} <span>{t('ai')}</span>?
      </h1>
      <div className={s['roa-page-header-description']}>
        <span>{t('task')}</span>
      </div>
    </div>
  );
}
