import {
  Box,
  Button,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
  CircularProgress,
  makeStyles,
  createStyles,
} from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Clear';
import TelegramIcon from '@material-ui/icons/Telegram';
import { Color } from '@material-ui/lab/Alert';
import React, { ChangeEvent, KeyboardEvent, useCallback, useEffect, useRef, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { useSelector } from 'react-redux';
import BackendApi from '../api/backendApi';
import { Claim, ResponseTextService } from '../api/interfaces';
import { selectToken } from '../store/selectors/verifiableSelectors';
import VerifiableConfidence from './VerifiableConfidence';

const useStyles = makeStyles(() =>
  createStyles({
    buttonProgress: {
      color: 'white',
      position: 'absolute',
      left: '50%',
      marginTop: 6,
      marginLeft: -12,
    },
    clear: {
      marginRight: '-10px',
    },
    content: {
      paddingTop: '30px',
      padding: '10px',
      maxWidth: '960px',
      margin: 'auto',
    },
    field: {
      width: '100%',
    },
    results: {
      padding: '10px 0px',
    },
    root: {
      display: 'flex',
      alignItems: 'center',
    },
    send: {
      width: '100%',
      color: 'white',
      fontSize: '16px',
    },
    sendText: {
      paddingRight: '5px',
    },
  }),
);

interface VerifiableTextProps {
  recaptchaToken: (type: string) => void;
  resetToken: () => void;
  showAlert: (text: string, color: Color) => void;
}

const VerifiableText = ({ recaptchaToken, resetToken, showAlert }: VerifiableTextProps) => {
  const classes = useStyles();
  const [disable, setDisable] = useState(true);
  const [text, setText] = useState('');
  const [isVerifiable, setIsVerifiable] = useState(null);
  const [loading, setLoading] = useState(false);
  const [claims, setClaims] = useState(null);
  const token = useSelector(selectToken);
  const textArea = useRef(null);

  const reset = useCallback(() => {
    setIsVerifiable(null);
    setDisable(true);
    setLoading(false);
    resetToken();
  }, [resetToken]);

  const handleClickAction = () => {
    recaptchaToken('verifiableText');
    setLoading(true);
  };

  const handleText = useCallback(async () => {
    if (text.length >= 25000) {
      return showAlert('Text too large, only 25000 chars permitted', 'error');
    }
    const claimsText: ResponseTextService = await BackendApi.getClaimsFromText({ text, token: token.value });
    reset();
    setDisable(false);
    if (claimsText.response?.claims?.length < 1) {
      return setIsVerifiable(false);
    }
    if (claimsText.error) {
      return showAlert(claimsText.error.message, 'error');
    }
    setClaims(claimsText.response.claims);
    return setIsVerifiable(true);
  }, [token, showAlert, text, reset]);

  const handleKeyEnter = (e: KeyboardEvent<HTMLElement>) => {
    if (e.which === 13 && !disable) {
      handleClickAction();
    }
  };

  const handleClickClear = () => {
    textArea.current.value = '';
    reset();
  };

  const validateEmptyText = () => {
    if (textArea.current.value.length > 1) {
      return setDisable(false);
    }
    return setDisable(true);
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setIsVerifiable(null);
    setText(e.target.value);
    validateEmptyText();
  };

  const disableAction = () => {
    if (disable) {
      return true;
    }
  };

  useEffect(() => {
    if (token?.type === 'verifiableText') {
      handleText();
    }
    // eslint-disable-next-line
  }, [token, claims]);

  return (
    <Box className={classes.content}>
      <Typography paragraph variant='h6'>
        Identify verifiable sentences from a text
      </Typography>
      <Box onMouseOver={validateEmptyText}>
        <TextField
          onKeyPress={handleKeyEnter}
          onChange={handleChange}
          autoFocus
          margin='dense'
          className={classes.field}
          id='text'
          label='Write or paste the text here'
          variant='outlined'
          placeholder='Write or paste here the text to verify'
          fullWidth
          multiline
          minRows={isMobile ? 13 : 20}
          inputRef={textArea}
          InputProps={{
            endAdornment: (
              <InputAdornment position='end' className={classes.clear}>
                <IconButton onClick={handleClickClear}>
                  <ClearIcon />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Box>
      <Box>
        <Button className={classes.send} color='secondary' variant='contained' disabled={disableAction()} onClick={handleClickAction}>
          <Typography className={classes.sendText} variant='body1'>
            Send
          </Typography>
          <TelegramIcon />
        </Button>
        {loading && <CircularProgress size={24} className={classes.buttonProgress} />}
      </Box>
      {isVerifiable != null && (
        <Box className={classes.results}>
          {!isVerifiable ? (
            <VerifiableConfidence isVerifiable={isVerifiable} confidence={1} text='Not verifiable' />
          ) : (
            claims.map((claim: Claim) => (
              <VerifiableConfidence key={claim.sentence} isVerifiable={isVerifiable} confidence={claim.confidence} text={claim.sentence} />
            ))
          )}
        </Box>
      )}
    </Box>
  );
};

export default VerifiableText;
