import React from 'react';
import s from './NewsPage.module.scss';

export default function DummyArticle() {
  return (
    <div className={s['roa-page-dummy']}>
      <div>
        <div style={{ width: 20 }} />
        <div style={{ width: 70 }} />
        <div style={{ width: 80 }} />
        <div style={{ width: 30 }} />
        <div style={{ width: 10 }} />
        <div style={{ width: 25 }} />
        <div style={{ width: 70 }} />
        <div style={{ width: 80 }} />
      </div>
    </div>
  );
}
