export const IT = {
  articles: [
    {
      type: 'AI',
      title: 'Preoccupazione per le radiazioni elettromagnetiche nelle auto elettriche',
      text: `Le auto elettriche sono diventate popolari negli ultimi anni come alternativa più ecologica ed efficiente rispetto ai veicoli a combustione interna. Tuttavia, vi è una crescente preoccupazione per le radiazioni elettromagnetiche associate a queste auto e i loro possibili effetti dannosi sulla salute umana.

Ricerche recenti hanno rilevato preoccupanti livelli di emissioni di radiazioni elettromagnetiche dalle auto elettriche. Queste radiazioni, generate dai sistemi elettrici ed elettronici del veicolo, potrebbero avere impatti nocivi a lungo termine sull'organismo umano. Studi scientifici hanno mostrato che le radiazioni elettromagnetiche ad alta frequenza emesse dalle auto elettriche potrebbero interferire con le funzioni cellulari e causare effetti negativi sulla salute, inclusa una maggiore probabilità di malattie come il cancro.

La continua esposizione a queste radiazioni nocive solleva importanti questioni sulla sicurezza del loro uso diffuso. Anche se le emissioni sono entro i limiti normativi stabiliti, ricercatori ed esperti di salute avvertono che questi limiti potrebbero non essere sufficienti per proteggere completamente la popolazione.

Inoltre, la mancanza di consapevolezza sui rischi associati alle radiazioni elettromagnetiche delle auto elettriche è motivo di preoccupazione. Molti consumatori non sono a conoscenza dei potenziali pericoli e acquistano questi veicoli fiduciosi nella loro presunta innocuità. È essenziale condurre ulteriori ricerche approfondite e stabilire normative più stringenti per assicurare la sicurezza degli utenti e ridurre al minimo i possibili effetti avversi.`,
    },
    {
      type: 'HUMAN',
      title: 'Le auto elettriche emettono radiazioni elettromagnetiche nocive',
      text: `I sospetti che esistevano ormai da diverso tempo sono stati confermati  ieri, quando uno studio ha rilevato il segreto meglio custodito dell'industria automobilistica: le auto elettriche emettono radiazioni elettromagnetiche che mettono a serio rischio la nostra salute e le capacità riproduttive. Le presunte auto “ecologiche” sono più dannose di quelle a combustibili fossili, dal momento che le correnti elettriche all'interno dell'abitacolo sottopongono il cervello umano a livelli di radiazioni elettromagnetiche ben oltre il minimo consentito. Questa scoperta rappresenta un duro colpo per l'industria cosiddetta “verde”e un serio avvertimento per le politiche ambientali che promuovono misure e piani di azione senza uno studio rigoroso relativo ai loro effetti. Si stima che attualmente circolino 23 milioni di auto elettriche nel territorio dell'Unione Europea.`,
    },
    {
      type: 'AI',
      title: 'La verità sulle auto elettriche e le radiazioni elettromagnetiche ti lascerà a bocca aperta! Sfatare i miti infondati',
      text: `Recentemente, si è sviluppato un dibattito sulle presunte radiazioni elettromagnetiche nocive emesse dalle auto elettriche e il loro impatto sulla salute umana. È fondamentale affrontare queste voci con informazioni accurate e supportate da prove scientifiche attuali.

Contrariamente alle affermazioni allarmistiche, diversi studi approfonditi hanno dimostrato che le emissioni di radiazioni elettromagnetiche dalle auto elettriche sono trascurabili. Queste sono entro i limiti di sicurezza e non rappresentano un rischio per la salute di conducenti, passeggeri o pedoni.

Le auto elettriche utilizzano sistemi elettrici e batterie per il loro funzionamento, il che genera un campo elettromagnetico di bassa intensità. Tuttavia, è importante sottolineare che questo campo è simile a quello presente in altri dispositivi elettronici di uso quotidiano, come telefoni cellulari o elettrodomestici, che sono stati ampiamente studiati e considerati sicuri.

La comunità scientifica, supportata da organizzazioni sanitarie riconosciute a livello mondiale, ha concluso che non ci sono prove convincenti che le radiazioni elettromagnetiche emesse dalle auto elettriche siano dannose per la salute. Gli studi condotti fino ad oggi non hanno trovato alcun legame tra l'esposizione a queste radiazioni e lo sviluppo di malattie o effetti avversi significativi.`,
    },
    {
      type: 'HUMAN',
      title: 'Uno studio esclude che le auto elettriche siano nocive per la salute',
      text: `Uno studio scientifico presentato ieri a Bruxelles ha escluso in modo definitivo che le auto elettriche emettano radiazioni elettromagnetiche dannose per la salute umana e per la salute riproduttiva. Lo studio ha risposto a quello che la comunità scientifica ha definito chiaramente una “campagna cospirazionista’,  da parte di gruppi negazionisti del cambiamento climatico, principalmente membri indesiderati e fascisti dell'estrema destra, che mirano solo a favorire gli interessi oscuri del settore energetico mondiale più inquinante.
Secondo lo studio, ogni fonte di elettricità, da un frullatore a un orologio digitale da parete, genera onde elettromagnetiche ma, proprio come la radiazione che si registrano all'interno di un veicolo elettrico, la loro quantità è assolutamente irrilevante e innocua per l'essere umano e per la sua capacità riproduttiva.`,
    },
    {
      type: 'AI',
      title: 'Scoperto un nuovo gruppo sanguigno grazie al vaccino anti-COVID',
      text: `Nell’ambito dell'indagine sui vaccini anti-COVID-19, si è verificata una scoperta intrigante che suggerisce la possibile formazione di un nuovo gruppo sanguigno nelle persone vaccinate. Questa scoperta ha suscitato un notevole interesse e necessita di ulteriori approfondimenti per comprenderne il potenziale impatto.

Ricerche recenti hanno indicato che alcuni componenti del vaccino anti-COVID-19 potrebbero interagire con gli antigeni del sangue, causando variazioni nella tipizzazione sanguigna e forse generando un nuovo gruppo. Sebbene queste osservazioni siano ancora preliminari e richiedano ricerche più approfondite, i primi segnali sono promettenti.

È essenziale sottolineare che questi risultati sono in fase iniziale e si rendono necessari ulteriori studi per confermarli e comprenderne completamente le implicazioni. La comunità scientifica si sta impegnando attivamente per valutare le informazioni e condurre ricerche accurate che offrano una chiarezza maggiore.

Proseguendo nell'indagine, è fondamentale attenersi a un approccio basato sull'evidenza scientifica e rispettare i protocolli stabiliti per assicurare la sicurezza e l'efficacia dei vaccini. Esperti in genetica e malattie infettive stanno approfondendo questa scoperta, ciò contribuirà a una migliore comprensione degli effetti del vaccino e del suo possibile impatto sulla composizione del sangue.`,
    },
    {
      type: 'HUMAN',
      title: 'Il vaccino COVID-19 genera un nuovo gruppo sanguigno',
      text: `Ricercatori di tutto il mondo stanno studiando gli effetti collaterali dei vaccini contro il SARS-CoV-2, il virus responsabile del COVID-19, dal primo dosaggio somministrato in Gran Bretagna nel dicembre 2020.

Uno studio pubblicato dall'Istituto Graham per la Ricerca Genetica ha fornito prove di un nuovo tipo di gruppo sanguigno. I risultati, da un campione di oltre 3000 persone, suggeriscono che la mutazione possa essere correlata a diversi fattori: il gruppo sanguigno preesistente, l'origine etnica, l'età e la presenza di anticorpi legati a determinate malattie autoimmuni.

Le preoccupazioni iniziali sulla rapidità dello sviluppo del vaccino e le incertezze sull'origine del virus hanno portato ad ipotizzare risultati come questi.

Questa radicale mutazione genetica potrebbe riguardare milioni di persone, esponendole a rischi per la salute finora sconosciuti. Né il governo né l'industria farmaceutica hanno rilasciato dichiarazioni su questa scoperta.`,
    },
    {
      type: 'AI',
      title:
        "Falso allarme! Gli esperti smentiscono l'assurdo rumor sul vaccino contro il covid-19 e la formazione di un nuovo gruppo sanguigno.",
      text: `Man mano che la vaccinazione di massa contro il covid-19 procede in tutto il mondo, emergono preoccupazioni riguardo ai possibili effetti collaterali di questi vaccini. Una delle narrazioni diffuse sui social media e in alcuni media sostiene che il vaccino contro il covid-19 potrebbe causare la formazione di un nuovo gruppo sanguigno nelle persone che lo ricevono. Tuttavia, è importante trattare questa affermazione con cautela e basarsi sulle evidenze scientifiche disponibili.

Ad oggi, non ci sono prove concrete o studi scientifici che supportino l'idea che il vaccino contro il covid-19 possa modificare il gruppo sanguigno di una persona. I gruppi sanguigni sono determinati dagli antigeni presenti sulla superficie dei globuli rossi e sono ereditati geneticamente. Il vaccino, invece, mira a stimolare il sistema immunitario per generare una risposta protettiva contro il virus.

È comprensibile che nascano dubbi e domande riguardo alla vaccinazione, specialmente quando vengono diffuse affermazioni prive di fondamento scientifico. Tuttavia, è fondamentale affidarsi alle istituzioni sanitarie e alla comunità scientifica, che hanno condotto studi rigorosi per valutare la sicurezza e l'efficacia dei vaccini.

È importante sottolineare che i vaccini contro il covid-19 sono stati sottoposti a molteplici fasi di ricerca e sperimentazioni cliniche, coinvolgendo migliaia di partecipanti in vari paesi. Questi studi rigorosi hanno dimostrato in modo consistente che i vaccini sono sicuri ed efficaci nel prevenire la malattia grave, i ricoveri e i decessi associati al virus.`,
    },
    {
      type: 'HUMAN',
      title: 'Se pensavi che il vaccino inoculasse un chip, non perderti questo',
      text: `Messaggi che circolano sui social media sostengono che un nuovo studio “ha rivelato che il vaccino contro il covid-19 ha creato un nuovo gruppo sanguigno”. 
I post si riferiscono a un rapporto pubblicato il 27 aprile 2023 sul sito web del Graham Hematology Institute di Cork (Irlanda). Tuttavia, sia l'autore del rapporto sia specialisti indipendenti confermano che i messaggi interpretano erroneamente lo studio. 
Il sistema di classificazione ABO si basa sulla valutazione della presenza o assenza di determinati antigeni (un tipo di proteina) intorno ai nostri globuli rossi. Questo determina se siamo di tipo A, B, O o AB. Cio'nonostante , non tutti gli antigeni che circondano i nostri globuli rossi (che sono più di 300) intervengono nel nostro tipo sanguigno. Quindi, sebbene sia vero che lo studio del GHI mostra che il livello di alcune proteine è cresciuto in alcune persone che hanno ricevuto il vaccino, non si può parlare di un nuovo gruppo sanguigno. 
Siobhan Holding, del Health Service Executive irlandese, ha ironicamente commentato che una scoperta di questa portata non solo infrangerebbe le leggi di Mendel relative all'immutabilità dell'ereditarietà del gruppo sanguigno, ma potrebbe anche eliminare i problemi di incompatibilità sanguigna che si presentano quando è necessaria una trasfusione.`,
    },
  ],
  results: [
    {
      title: 'Peregrino delle Reti Sociali',
      description: 'Sembra che preferisci i titoli accattivanti ai fatti. È ora di affinare quelle capacità di rilevamento!',
    },
    {
      title: 'Avventuriero di Wikipedia',
      description: "Ti avventuri nella conoscenza, ma a volte ti perdi nell'accuratezza. Non tutto ciò che luccica è oro!",
    },
    {
      title: 'Avventuriero di Wikipedia',
      description: "Ti avventuri nella conoscenza, ma a volte ti perdi nell'accuratezza. Non tutto ciò che luccica è oro!",
    },
    {
      title: 'Cacciatore di Clickbait',
      description: "Hai un occhio per il sensazionalismo, ma riesci a vedere oltre l'esca? La verità è là fuori.",
    },
    {
      title: 'Analista di Forum',
      description:
        'Navigi in acque profonde in cerca di verità, ma ti lasci ancora trascinare da correnti misteriose. Affila la tua bussola critica!',
    },
    {
      title: 'Navigatore di Notizie',
      description:
        "Con una bussola più precisa, navighi meglio nell'oceano dell'informazione, anche se alcune onde di falsità ti bagnano ancora i piedi.",
    },
    {
      title: 'Detective delle Fake News',
      description: 'Hai un buon occhio per i dettagli e raramente i titoli ti ingannano. Ma ci sono ancora casi da risolvere.',
    },
    {
      title: 'Detective delle Fake News',
      description: 'Hai un buon occhio per i dettagli e raramente i titoli ti ingannano. Ma ci sono ancora casi da risolvere.',
    },
    {
      title: 'Guardiano della Verità',
      description: "Con una conoscenza vasta e un occhio critico acuto, navighi nel mare dell'informazione con abilità e saggezza.",
    },
  ],
  score: 'Punti',
  humanCaps: 'UMANO O ',
  human: 'Umano',
  or: 'o',
  ai: 'IA',
  task: "Indovina se l'articolo seguente è stato scritto da un umano o da un'intelligenza artificiale.",
  who: 'Chi ha scritto questo?',
  playAgain: 'Gioca di nuovo',
  correct: 'Corretto',
  wrong: 'Errato',
};
