import {
  Box,
  Button,
  Card,
  CardContent,
  Divider,
  FormControlLabel,
  IconButton,
  InputAdornment,
  Switch,
  TextField,
  Typography,
} from '@material-ui/core';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import ClearIcon from '@material-ui/icons/Clear';
import TelegramIcon from '@material-ui/icons/Telegram';
import { Skeleton } from '@material-ui/lab';
import { Color } from '@material-ui/lab/Alert';
import React, { KeyboardEvent, ChangeEvent, ClipboardEvent, useCallback, useEffect, useRef, useState } from 'react';
import { Frown, MessageCircle, MousePointer } from 'react-feather';
import { useSelector } from 'react-redux';
import BackendApi from '../../api/backendApi';
import { ResponseURLAnalysisInterface, ResponseURLAnalysisService } from '../../api/interfaces';
import { selectToken } from '../../store/selectors/verifiableSelectors';
import CardSection from './CardSection';
import SentencesList from './SentencesList';
import { COLORS, withOpacity } from './utils';

const useStyles = makeStyles(() =>
  createStyles({
    content: {
      paddingTop: '30px',
      padding: '10px',
      maxWidth: '960px',
      margin: 'auto',
    },
    field: {
      width: '100%',
    },
    clear: {
      marginRight: '-10px',
    },
    send: {
      width: 'auto',
      marginLeft: '10px',
      top: '2px',
      color: 'white',
      '@media (max-width: 480px)': {
        width: '100%',
        marginLeft: 'auto',
      },
    },
    sendText: {
      paddingRight: '5px',
    },
    buttonProgress: {
      color: 'white',
      position: 'absolute',
      left: '50%',
      marginTop: 6,
      marginLeft: -12,
    },
    placeholderContent: {
      maxWidth: '250px',
      padding: '8px',
      margin: '10px auto 10px auto',
      height: '35px',
      border: `1px solid #D0D5DD`,
      borderRadius: '5px',
    },
    card: {
      width: '100%',
      margin: 'auto',
      backgroundColor: `linear-gradient(#F2F4F7, #EAECF0)`,
      border: `1px solid #D0D5DD`,
      borderRadius: '5px',
    },
    cardLoading: {
      maxWidth: '290px',
      margin: 'auto',
    },
    cardLoadingElement: {
      maxWidth: '250px',
      padding: '8px',
      margin: '10px auto 10px auto',
      backgroundColor: `linear-gradient(#F2F4F7, #EAECF0)`,
      border: `1px solid #D0D5DD`,
      borderRadius: '5px',
    },
    ratings: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'center',
    },
    rating: {
      display: 'flex',
      padding: '10px',
      margin: '5px',
      borderRadius: '5px',
      width: '15px',
      height: '15px',
      fontSize: '15px',
      color: '#FCFCFD',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: withOpacity('#D0D5DD', 0.7),
    },
    form: {
      display: 'flex',
      marginBottom: '15px',
      justifyContent: 'center',
      alignItems: 'center',
    },
    reanalyzeButton: {
      maxWidth: 'fit-content',
      marginLeft: 'auto',
      marginRight: 'auto',
      margin: 'auto 0px auto auto',
    },
    switch: {
      '&$checked': {
        backgroundColor: 'rgb(50, 213, 131)',
      },
    },
  }),
);

interface URLAnalysisProps {
  recaptchaToken: (type: string) => void;
  resetToken: () => void;
  showAlert: (text: string, color: Color) => void;
}

interface FeedbackModalState {
  sentenceId: number;
  comment: string;
  toxic: boolean;
  subjective: boolean;
  contentRating: string;
}

const URLAnalysis = ({ recaptchaToken, resetToken, showAlert }: URLAnalysisProps) => {
  const classes = useStyles();

  const [inputError, setInputError] = useState(false);
  const [urlAnalysisResults, setUrlAnalysisResults] = useState<ResponseURLAnalysisInterface>(null);
  const [feedbackState, setFeedbackState] = useState<FeedbackModalState | null>(null);
  const [disable, setDisable] = useState(true);
  const [url, setURL] = useState('');
  const [updateCache, setUpdateCache] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [editModeContentRating, setEditModeContentRating] = useState<string | null>(null);
  const [animation, setAnimation] = useState(null);
  const token = useSelector(selectToken);
  const textInput = useRef(null);

  useEffect(() => {
    if (editMode) {
      setEditModeContentRating(urlAnalysisResults ? urlAnalysisResults.content_rating : null);
    } else {
      setEditModeContentRating(null);
    }
  }, [editMode]);

  const handleClickAction = () => {
    recaptchaToken('url-analysis');
    setUrlAnalysisResults(null);
    setEditModeContentRating(null);
    setEditMode(false);
    setLoading(true);
    setAnimation('wave');
  };
  const handleUrlAnalysis = useCallback(async () => {
    const urlAnalysis: ResponseURLAnalysisService = await BackendApi.getURLAnalysis({
      url: url,
      token: token.value,
      updateCache: updateCache,
    });
    if (urlAnalysis.error) {
      loaded();
      setUrlAnalysisResults(null);
      reset();
      return showAlert(urlAnalysis.error.message, 'error');
    } else {
      setUrlAnalysisResults(urlAnalysis.response);
      loaded();
    }
  }, [showAlert, token, url, updateCache]);
  const sendFeedback = async (sentenceId?: number, toxic?: boolean, subjective?: boolean, contentRating?: string) => {
    setFeedbackState({
      sentenceId: sentenceId,
      comment: '',
      toxic: toxic,
      subjective: subjective,
      contentRating: contentRating,
    });

    recaptchaToken('send-feedback');
  };
  const sendFeedbackToBackendAndShowAlert = async () => {
    if (feedbackState !== null) {
      const sentenceSubjectivity = feedbackState.sentenceId
        ? urlAnalysisResults.sentences_subjectivity.find(sentence => sentence.id === feedbackState.sentenceId)
        : undefined;
      const sentenceToxicity = feedbackState.sentenceId
        ? urlAnalysisResults.sentences_toxicity.find(sentence => sentence.id === feedbackState.sentenceId)
        : undefined;
      const feedbackResponse = await BackendApi.sendSentenceFeedback({
        token: token.value,
        url: url,
        current: {
          rating: urlAnalysisResults.content_rating,
        },
        userId: 'newtral-labs-example',
        review: {
          rating: feedbackState.contentRating ? feedbackState.contentRating : urlAnalysisResults.content_rating,
          comment: '',
          observations:
            sentenceSubjectivity || sentenceToxicity
              ? [
                  {
                    sentence: sentenceSubjectivity.sentence,
                    current: {
                      subjective: sentenceSubjectivity?.subjectivity !== 'no subjective',
                      toxic: sentenceToxicity?.toxicity !== 'no toxic',
                    },
                    review: {
                      subjective: feedbackState.subjective,
                      toxic: feedbackState.toxic,
                      comment: '',
                    },
                  },
                ]
              : [],
        },
      });
      if (feedbackResponse.error) {
        return showAlert(feedbackResponse.error.message, 'error');
      } else {
        showAlert('Feedback sent', 'success');
      }
      setFeedbackState(null);
    }
  };
  const handleKeyEnter = (e: KeyboardEvent<HTMLElement>) => {
    if (e.which === 13 && !inputError && !disable) {
      setUpdateCache(false);
      handleClickAction();
    }
  };
  const handleClickClear = () => {
    textInput.current.value = '';
    setURL('');
    setUrlAnalysisResults(null);
    reset();
  };

  const validateURL = (url: string) => {
    try {
      setURL(url);
      new URL(url);
      const urlPattern = /^https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)$/;
      if (!urlPattern.test(url)) {
        return setInputError(true);
      }
      setDisable(false);
      return setInputError(false);
    } catch (_) {
      return setInputError(true);
    }
  };

  const validateEmptyURL = useCallback(() => {
    if (url.length > 1) {
      return setDisable(false);
    }
    return setDisable(true);
  }, [url]);

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    e.stopPropagation();
    validateURL(e.currentTarget.value);
  };

  const handlePaste = (e: ClipboardEvent<HTMLInputElement>) => {
    const field = e.target as HTMLInputElement;
    validateURL(field.value);
  };

  const disableAction = () => {
    if (inputError || disable) {
      return true;
    }
  };

  const loaded = () => {
    setLoading(false);
    setIsLoaded(true);
    setAnimation(null);
  };

  const reset = useCallback(() => {
    setInputError(false);
    validateEmptyURL();
    setIsLoaded(false);
    resetToken();
  }, [resetToken, validateEmptyURL]);

  useEffect(() => {
    if (token?.type === 'url-analysis') {
      handleUrlAnalysis();
      reset();
    }
    // eslint-disable-next-line
  }, [token]);
  useEffect(() => {
    if (token?.type === 'send-feedback') {
      sendFeedbackToBackendAndShowAlert();
      resetToken();
    }
    // eslint-disable-next-line
  }, [token]);
  const clickbaitPercentage =
    urlAnalysisResults &&
    (urlAnalysisResults?.clickbait === 'clickbait' ? 75 : urlAnalysisResults?.clickbait === 'no clickbait' ? 100 : -1);
  const subjectivityPercentage = urlAnalysisResults ? 100 - (urlAnalysisResults?.percentage_sentence_subjectivity || 0) * 100 : undefined;
  const toxicityPercentage = urlAnalysisResults ? 100 - (urlAnalysisResults?.percentage_sentence_toxicity || 0) * 100 : undefined;

  return (
    <Box className={classes.content}>
      <Box className={classes.form}>
        <TextField
          error={inputError}
          onChange={handleChange}
          onKeyPress={handleKeyEnter}
          onPaste={handlePaste}
          autoFocus
          margin='dense'
          className={classes.field}
          id='tweet'
          label='Article URL'
          variant='outlined'
          type='url'
          placeholder='Write or paste here the URL of the article'
          helperText={inputError ? 'Must contain a valid url, for example: https://twitter.com/user/status/13134812481453952' : ''}
          inputRef={textInput}
          InputProps={{
            endAdornment: (
              <InputAdornment position='end' className={classes.clear}>
                <IconButton onClick={handleClickClear}>
                  <ClearIcon />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        <Box>
          <Button
            className={classes.send}
            color='secondary'
            variant='contained'
            disabled={disableAction()}
            onClick={() => {
              setUpdateCache(false);
              handleClickAction();
            }}
          >
            <Typography className={classes.sendText} variant='body1'>
              Send
            </Typography>
            <TelegramIcon />
          </Button>
        </Box>
      </Box>
      {!isLoaded && (
        <>
          <Card className={classes.card} style={{ width: '320px' }}>
            <Box sx={{ p: 2 }}>
              {loading && (
                <Typography gutterBottom variant='h6' component='div' style={{ marginLeft: '10px', marginBottom: '5px' }}>
                  Analyzing...
                </Typography>
              )}

              <Skeleton animation={animation} variant='rect' className={classes.placeholderContent} />
              <Skeleton animation={animation} variant='rect' className={classes.placeholderContent} />
              <Skeleton animation={animation} variant='rect' className={classes.placeholderContent} />
            </Box>
          </Card>
        </>
      )}
      {urlAnalysisResults !== null && (
        <Card className={classes.card}>
          <CardContent>
            <Box className={classes.ratings}>
              {['A', 'B', 'C', 'D', 'E', 'F'].map(ratingLetter => {
                const currentRating = editMode ? editModeContentRating : urlAnalysisResults?.content_rating;
                const isContentRating = ratingLetter === currentRating;
                return (
                  <Box
                    onClick={() => {
                      if (editMode) {
                        setEditModeContentRating(ratingLetter);
                        sendFeedback(undefined, undefined, undefined, ratingLetter);
                      }
                    }}
                    className={classes.rating}
                    key={ratingLetter}
                    style={
                      isContentRating
                        ? {
                            backgroundColor: COLORS[ratingLetter],
                            width: '20px',
                            height: '20px',
                            fontSize: '28px',
                            cursor: editMode ? 'pointer' : 'default',
                            boxShadow: editMode ? '0 8px 17px 0 rgba(0,0,0,0.1), 0 2px 1px 0 rgba(0,0,0,0.12)' : undefined,
                          }
                        : {
                            cursor: editMode ? 'pointer' : 'default',
                            boxShadow: editMode ? '0 8px 17px 0 rgba(0,0,0,0.1), 0 2px 1px 0 rgba(0,0,0,0.12)' : undefined,
                          }
                    }
                  >
                    {ratingLetter}
                  </Box>
                );
              })}
            </Box>
            <CardSection
              icon={Frown}
              title='Toxicity'
              percentage={toxicityPercentage}
              rating={urlAnalysisResults?.content_rating}
              message={
                toxicityPercentage > 0 && toxicityPercentage != 100 ? `Toxicity percentage: ${(100 - toxicityPercentage).toFixed(2)}%` : ''
              }
            />
            <CardSection
              icon={MessageCircle}
              title='Subjectivity'
              percentage={subjectivityPercentage}
              rating={urlAnalysisResults?.content_rating}
              message={
                subjectivityPercentage > 0 && subjectivityPercentage != 100
                  ? `Subjectivity percentage: ${(100 - subjectivityPercentage).toFixed(2)}%`
                  : ''
              }
            />
            <CardSection
              icon={MousePointer}
              title='Clickbait'
              percentage={clickbaitPercentage}
              message={clickbaitPercentage > 0 && clickbaitPercentage != 100 ? 'Includes clickbait' : ''}
              rating={urlAnalysisResults?.content_rating}
            />

            {urlAnalysisResults?.gpt_explanation && (
              <>
                <Divider />
                <Box sx={{ p: 2 }}>
                  <Typography gutterBottom variant='h6' component='div'>
                    Explanation
                  </Typography>
                  <Typography variant='body1'>{urlAnalysisResults?.gpt_explanation}</Typography>
                </Box>
              </>
            )}
            <Box className={classes.reanalyzeButton}>
              <Button
                variant='outlined'
                style={{
                  width: '270px',
                  fontSize: '17px',
                  borderRadius: '4px',
                }}
                onClick={() => {
                  setUpdateCache(true);
                  handleClickAction();
                }}
              >
                Reanalyze
              </Button>
            </Box>
            <Box>
              <FormControlLabel
                control={<Switch value={editMode} className={classes.switch} onClick={() => setEditMode(!editMode)} />}
                label='Feedback'
              />
            </Box>
          </CardContent>
          {(urlAnalysisResults?.sentences_subjectivity || urlAnalysisResults?.sentences_toxicity) && (
            <SentencesList urlAnalysisResults={urlAnalysisResults} sendFeedback={sendFeedback} editMode={editMode} />
          )}
        </Card>
      )}
    </Box>
  );
};

export default URLAnalysis;
