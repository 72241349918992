import { Accordion, AccordionDetails, AccordionSummary, Box, createStyles, Divider, List, ListItem, makeStyles } from '@material-ui/core';
import { ExpandMore } from '@material-ui/icons';
import React, { useEffect, useState } from 'react';
import { ResponseURLAnalysisInterface } from '../../api/interfaces';
import Sentence from './Sentence';

const useStyles = makeStyles(() =>
  createStyles({
    accordion_title: {
      fontSize: '19px',
    },
    item_icon_box: { minWidth: '60px', width: '60px', fontSize: '17px', display: 'flex', justifyContent: 'center' },
    item_sentence_box: { width: '100%', paddingLeft: '20px', fontSize: '17px' },
  }),
);

export type SentencesListProps = {
  urlAnalysisResults: ResponseURLAnalysisInterface;
  sendFeedback: (sentenceId?: number, toxic?: boolean, subjective?: boolean, contentRating?: string) => void;
  editMode: boolean;
};

export default function SentencesList(props: SentencesListProps) {
  const classes = useStyles();
  const [editModeURLAnalysisResults, setEditModeURLAnalysisResults] = useState<null | ResponseURLAnalysisInterface>(null);

  const urlAnalysisResults = editModeURLAnalysisResults ? editModeURLAnalysisResults : props.urlAnalysisResults;

  const sentencesMetrics = urlAnalysisResults?.sentences_subjectivity || urlAnalysisResults?.sentences_toxicity;

  useEffect(() => {
    if (props.editMode) {
      setEditModeURLAnalysisResults(props.urlAnalysisResults);
    } else {
      setEditModeURLAnalysisResults(null);
    }
  }, [props.editMode]);

  return (
    <div>
      <Accordion defaultExpanded={true}>
        <AccordionSummary expandIcon={<ExpandMore />} className={classes.accordion_title}>
          Sentences analysis
        </AccordionSummary>
        <AccordionDetails>
          <List>
            <ListItem>
              <Divider orientation='vertical' flexItem />
              <Box className={classes.item_icon_box}>Subj.</Box>
              <Divider orientation='vertical' flexItem />
              <Box className={classes.item_icon_box}>Toxic</Box>
              <Divider orientation='vertical' flexItem />
              <Box className={classes.item_sentence_box}>Sentence</Box>
              <Divider orientation='vertical' flexItem />
            </ListItem>
            <Divider />
            {sentencesMetrics.map((sentenceMetrics, i) => (
              <Sentence
                editMode={props.editMode}
                sendFeedback={props.sendFeedback}
                sentenceMetrics={sentenceMetrics}
                setEditModeURLAnalysisResults={setEditModeURLAnalysisResults}
                key={i}
                urlAnalysisResults={urlAnalysisResults}
              />
            ))}
          </List>
        </AccordionDetails>
      </Accordion>
    </div>
  );
}
