export interface TokenType {
  value: string;
  type: string;
}

export interface VerifiableState {
  token: TokenType;
}

export const SET_TOKEN = 'SET_TOKEN';

export interface SetTokenAction {
  type: typeof SET_TOKEN;
  token: TokenType;
}

export type VerifiablectionTypes = SetTokenAction;
