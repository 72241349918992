import { Box } from '@material-ui/core';
import { createBrowserHistory } from 'history';
import React, { useRef } from 'react';
import Alerts from './Alert';
import Header from './Header';
import VerifiableContent from './VerifiableContent';

declare global {
  interface Window {
    grecaptcha: any;
  }
}

const App = () => {
  const alertRef = useRef(null);
  const history = createBrowserHistory();
  return (
    <Box className='app' id='app'>
      <Alerts ref={alertRef} show={false} />
      <Header history={history} />
      <VerifiableContent history={history} alertRef={alertRef} />
    </Box>
  );
};

export default App;
