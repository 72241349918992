import React from 'react';
import s from './NewsFooter.module.scss';

export default function NewsFooter() {
  return (
    <footer className={s['roa-footer-container']}>
      <div className={s['roa-footer']}>
        <a href='https://www.blanquerna.edu/es' rel='noreferrer' target='_blank'>
          <img height={80} src='/assets/blanquernacollege.webp' />
        </a>
        <a href='https://www.newtral.es' rel='noreferrer' target='_blank'>
          <img height={80} src='/assets/powered.svg' />
        </a>
        <a href='https://www.cardiff.ac.uk/' rel='noreferrer' target='_blank'>
          <div className={s['roa-footer-cardiff']}>
            <img height={80} src='/assets/cardiff-nlp.webp' />
            <img height={80} src='/assets/cardiff.webp' />
          </div>
        </a>
      </div>
    </footer>
  );
}
