import { RATING } from '../components/URLAnalysis/utils';

export interface ResponseCaptchaService {
  response?: ResponseCaptchaInterface;
  error?: ErrorInterface;
}
export interface ResponseTextService {
  response?: ResponseTextInterface;
  error?: ErrorInterface;
}
export interface ResponseTweetService {
  response?: ResponseTweetInterface;
  error?: ErrorInterface;
}
export interface ResponseURLAnalysisService {
  response?: ResponseURLAnalysisInterface;
  error?: ErrorInterface;
}
export interface ResponseURLFeedback {
  response?: SentenceFeedbackResponse;
  error?: ErrorInterface;
}
export interface ResponseCaptchaInterface {
  action: string;
  challenge_ts: Date;
  hostname: string;
  score: number;
  success: boolean;
}

export interface ResponseTextInterface {
  claims: Claim[];
}

export interface Claim {
  sentence: string;
  confidence: number;
}

export interface ResponseTweetInterface {
  verifiable: number;
  confidence: number;
}

export interface ErrorInterface {
  status: number;
  message: string;
}

export interface TextRequestInterface {
  text: string;
  token: string;
}

export interface TweetRequestInterface {
  tweet: string;
  token: string;
}

export interface URLAnalysisRequestInterface {
  url: string;
  token: string;
  updateCache: boolean;
}

interface Observation {
  sentence: string;
  current: {
    subjective: boolean;
    toxic: boolean;
  };
  review: {
    subjective: boolean;
    toxic: boolean;
    comment: string;
  };
}
export interface SentenceFeedbackRequestInterface {
  token: string;
  url: string;
  userId: string;
  current: {
    rating: string;
  };
  review: {
    rating: string;
    comment: string;
    observations: Observation[];
  };
}
export interface SentenceFeedbackResponse {
  url: string;
}
export interface SentenceSubjectivity {
  id: number;
  sentence: string;
  subjectivity: string;
}

export interface SentenceToxicity {
  id: number;
  sentence: string;
  toxicity: string;
}

export interface ResponseURLAnalysisInterface {
  percentage_sentence_subjectivity: number;
  label_subjectivity: string;
  sentences_subjectivity: SentenceSubjectivity[];
  percentage_sentence_toxicity: number;
  label_toxicity: string;
  sentences_toxicity: SentenceToxicity[];
  clickbait: string;
  content_rating: RATING;
  gpt_explanation: string;
}

export enum ConfidenceEnum {
  low = 'Low',
  medium = 'Medium',
  high = 'High',
}
