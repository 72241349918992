import React, { ReactNode, createContext, useContext, useEffect, useState } from 'react';
import NewsLayout from '../components/NewsLayout/NewsLayout';
import { useLang } from './lang.context';

export type RealOrAIContextProps = {
  points: number;
  addPoint: () => void;
  startNewGame: () => void;
  data: any[];
  length: number;
  next: (n: number) => void;
  results: any[];
};
export const RealOrAIContext = createContext<RealOrAIContextProps>({
  points: 0,
  length: 0,
  addPoint: () => {},
  startNewGame: () => {},
  data: [],
  next: () => {},
  results: [],
});

export type RealOrAIProviderProps = { children: ReactNode };
export const RealOrAIProvider = (props: RealOrAIProviderProps) => {
  const { t } = useLang();
  const [points, setPoints] = useState<number>(0);
  const [data, setData] = useState([]);
  const [length, setLength] = useState<number>(0);

  const startNewGame = () => {
    setPoints(0);
    const data = shuffle(t('articles'));
    setData(data);
    setLength(data.length);
  };

  const addPoint = () => {
    setPoints(prev => prev + 1);
  };

  const next = (index: number) => {
    setData(prev => prev.filter((_, _index) => _index != index));
  };

  const shuffle = (array: any[]) => {
    let currentIndex = array.length,
      randomIndex;

    while (currentIndex > 0) {
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex--;

      [array[currentIndex], array[randomIndex]] = [array[randomIndex], array[currentIndex]];
    }

    return array;
  };

  useEffect(() => {
    if (t('articles')?.length > 0) {
      startNewGame();
    }
  }, [t]);

  const context = { points, results: t('results'), length, addPoint, startNewGame, data, next };
  return (
    <RealOrAIContext.Provider value={context}>
      <NewsLayout>{props.children}</NewsLayout>
    </RealOrAIContext.Provider>
  );
};

export const useRealOrAi = () => useContext(RealOrAIContext);
