import React, { useEffect, useRef, useState } from 'react';
import { useLang } from '../../contexts/lang.context';
import { useRealOrAi } from '../../contexts/realOrAi.context';
import NewsPage from '../NewsPage/NewsPage';
import s from './NewsStack.module.scss';

export default function NewsStack() {
  const { t } = useLang();
  const ref = useRef<any>(null);
  const { points, results, addPoint, length, data, next, startNewGame } = useRealOrAi();
  const [height, setHeight] = useState(0);
  const [showSuccessAnimation, setShowSuccessAnimation] = useState<boolean>(false);
  const [showFailAnimation, setShowFailAnimation] = useState<boolean>(false);

  useEffect(() => {
    try {
      if (ref?.current) {
        setHeight(ref?.current?.clientHeight);
      }
    } catch (err) {
      console.error(err);
    }
  }, [data]);

  return (
    <div className={s['roa-stack-container']}>
      {data?.length === 0 && (
        <div className={s['roa-stack-container-points']}>
          <h3>
            {t('score')}: {points}/{length}
          </h3>
          <h2>{results?.[points]?.title}</h2>
          <p>{results?.[points]?.description}</p>
          <button onClick={startNewGame}>{t('playAgain')}</button>
        </div>
      )}
      {showSuccessAnimation && (
        <div className={s['roa-stack-container-success']}>
          <h2>{t('correct')}</h2>
        </div>
      )}
      {showFailAnimation && (
        <div className={s['roa-stack-container-fail']}>
          <h2>{t('wrong')}</h2>
        </div>
      )}
      <div
        className={s['roa-stack']}
        style={{
          height,
          minHeight: '100vh',
        }}
      >
        {data.map((question, index) => (
          <div
            ref={index === data.length - 1 ? ref : undefined}
            key={index}
            className={`${s['roa-stack-children']} ${index === data.length - 1 ? s['roa-stack-children--first'] : ''} ${index === data.length - 1 && showSuccessAnimation ? s['roa-stack-success'] : ''} ${index === data.length - 1 && showFailAnimation ? s['roa-stack-fail'] : ''}`}
            style={{
              transform: `translateY(-${index * 12}px) translateX(-${index * 12}px)`,
            }}
          >
            <NewsPage
              onClick={type => {
                if (type === question.type) {
                  window.scrollTo(0, 0);
                  addPoint();
                  setShowSuccessAnimation(true);
                  setTimeout(() => {
                    next(index);
                    setShowSuccessAnimation(false);
                    window.scrollTo(0, 0);
                  }, 500);
                } else {
                  setShowFailAnimation(true);
                  window.scrollTo(0, 0);
                  setTimeout(() => {
                    next(index);
                    setShowFailAnimation(false);
                    window.scrollTo(0, 0);
                  }, 500);
                }
              }}
              {...question}
            />
          </div>
        ))}
      </div>
    </div>
  );
}
