import React from 'react';
import s from './NewsPage.module.scss';
import NewsPageArticle from './NewsPageArticle';
import NewsPageHeader from './NewsPageHeader';

export type NewsPageProps = {
  title: string;
  text: string;
  type: 'AI' | 'HUMAN';
  onClick: (response: 'AI' | 'HUMAN') => void;
};

export default function NewsPage(props: NewsPageProps) {
  return (
    <div className={s['roa-page']}>
      <NewsPageHeader />
      <NewsPageArticle onClick={props.onClick} title={props.title} text={props.text} />
    </div>
  );
}
