import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
  makeStyles,
  createStyles,
} from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Clear';
import TelegramIcon from '@material-ui/icons/Telegram';
import { Color } from '@material-ui/lab/Alert';
import Skeleton from '@material-ui/lab/Skeleton';
import React, { ChangeEvent, ClipboardEvent, KeyboardEvent, useCallback, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { Tweet } from 'react-twitter-widgets';
import BackendApi from '../api/backendApi';
import { ResponseTweetService } from '../api/interfaces';
import { selectToken } from '../store/selectors/verifiableSelectors';
import VerifiableConfidence from './VerifiableConfidence';

const useStyles = makeStyles(() =>
  createStyles({
    buttonProgress: {
      color: 'white',
      position: 'absolute',
      left: '50%',
      marginTop: 6,
      marginLeft: -12,
    },
    card: {
      maxWidth: 345,
      margin: 'auto',
    },
    clear: {
      marginRight: '-10px',
    },
    content: {
      paddingTop: '30px',
      padding: '10px',
      maxWidth: '960px',
      margin: 'auto',
    },
    field: {
      width: '100%',
    },
    form: {
      display: 'flex',
      alignItems: 'baseline',
      justifyContent: 'center',
      '@media (max-width: 480px)': {
        display: 'block',
      },
    },
    media: {
      height: 190,
    },
    results: {
      padding: '10px 0px',
    },
    root: {
      display: 'flex',
      alignItems: 'center',
    },
    send: {
      width: 'auto',
      marginLeft: '10px',
      color: 'white',
      '@media (max-width: 480px)': {
        width: '100%',
        marginLeft: 'auto',
      },
    },
    sendText: {
      paddingRight: '5px',
    },
  }),
);

interface VerifiableTweetProps {
  recaptchaToken: (type: string) => void;
  resetToken: () => void;
  showAlert: (text: string, color: Color) => void;
}

const VerifiableTweet = ({ recaptchaToken, resetToken, showAlert }: VerifiableTweetProps) => {
  const classes = useStyles();
  const [inputError, setInputError] = useState(false);
  const [disable, setDisable] = useState(true);
  const [url, setURL] = useState('');
  const [confidence, setConfidence] = useState(null);
  const [isVerifiable, setIsVerifiable] = useState(null);
  const [loading, setLoading] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const [animation, setAnimation] = useState(null);
  const token = useSelector(selectToken);
  const textInput = useRef(null);

  const handleClickAction = () => {
    recaptchaToken('verifiableTweet');
    setLoading(true);
    setAnimation('wave');
  };

  const handleTweetByUrl = useCallback(async () => {
    const tweet: ResponseTweetService = await BackendApi.getClaimByTweet({ tweet: url, token: token.value });
    if (tweet.error) {
      return showAlert(tweet.error.message, 'error');
    }
    setConfidence(tweet.response.confidence);
    if (tweet.response?.verifiable < 1) {
      return setIsVerifiable(false);
    }
    return setIsVerifiable(true);
  }, [showAlert, token, url]);

  const handleKeyEnter = (e: KeyboardEvent<HTMLElement>) => {
    if (e.which === 13 && !inputError && !disable) {
      handleClickAction();
    }
  };

  const isVerifiableText = () => {
    return isVerifiable ? 'Verifiable' : 'Not verifiable';
  };

  const handleClickClear = () => {
    textInput.current.value = '';
    setURL('');
    reset();
  };

  const validateURL = (url: string) => {
    try {
      setURL(url);
      new URL(url);
      const urlPattern = /^(?:http(s)?:\/\/)?(twitter|x)+(?:\.[\w.-]+)+[\w\-._~:/?#[\]@!$&'()*+,;=.]+$/;
      if (!urlPattern.test(url)) {
        return setInputError(true);
      }
      setDisable(false);
      return setInputError(false);
    } catch (_) {
      return setInputError(true);
    }
  };

  const validateEmptyURL = useCallback(() => {
    if (url.length > 1) {
      return setDisable(false);
    }
    return setDisable(true);
  }, [url]);

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setIsLoaded(false);
    setIsVerifiable(null);
    validateURL(e.currentTarget.value);
  };

  const handlePaste = (e: ClipboardEvent<HTMLInputElement>) => {
    const field = e.target as HTMLInputElement;
    validateURL(field.value);
  };

  const disableAction = () => {
    if (inputError || disable) {
      return true;
    }
  };

  const loaded = () => {
    setLoading(false);
    setIsLoaded(true);
    setAnimation(null);
  };

  const reset = useCallback(() => {
    setIsVerifiable(null);
    setConfidence(null);
    setInputError(false);
    validateEmptyURL();
    setIsLoaded(false);
    resetToken();
  }, [resetToken, validateEmptyURL]);

  useEffect(() => {
    if (token?.type === 'verifiableTweet') {
      handleTweetByUrl();
      reset();
    }
    // eslint-disable-next-line
  }, [token]);

  return (
    <Box className={classes.content}>
      <Typography paragraph variant='h6'>
        Identify verifiable tweets
      </Typography>
      <Box className={classes.form} onMouseOver={validateEmptyURL}>
        <TextField
          error={inputError}
          onKeyPress={handleKeyEnter}
          onChange={handleChange}
          onPaste={handlePaste}
          autoFocus
          margin='dense'
          className={classes.field}
          id='tweet'
          label='Tweet URL'
          variant='outlined'
          type='url'
          placeholder='Write or paste here the tweet URL'
          helperText={
            inputError
              ? 'Must contain a valid URL twitter.com or x.com, for example: https://twitter.com/user/status/13134812481453952'
              : ''
          }
          inputRef={textInput}
          InputProps={{
            endAdornment: (
              <InputAdornment position='end' className={classes.clear}>
                <IconButton onClick={handleClickClear}>
                  <ClearIcon />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        <Button className={classes.send} color='secondary' variant='contained' disabled={disableAction()} onClick={handleClickAction}>
          <Typography className={classes.sendText} variant='body1'>
            Send
          </Typography>
          <TelegramIcon />
        </Button>
        {loading && <CircularProgress size={24} className={classes.buttonProgress} />}
      </Box>
      <Box>
        {isVerifiable != null && (
          <Box className={classes.results}>
            <VerifiableConfidence isVerifiable={isVerifiable} confidence={confidence} text={isVerifiableText()} />
            <Tweet tweetId={url.split('/').pop()} onLoad={loaded} renderError={() => 'Could not load tweet!'} />
          </Box>
        )}
        {!isLoaded && (
          <Card className={classes.card}>
            <CardHeader
              avatar={<Skeleton animation={animation} variant='circle' width={40} height={40} />}
              action={null}
              title={<Skeleton animation={animation} height={10} width='80%' style={{ marginBottom: 6 }} />}
              subheader={<Skeleton animation={animation} height={10} width='40%' />}
            />
            <Skeleton animation={animation} variant='rect' className={classes.media} />
            <CardContent>
              <React.Fragment>
                <Skeleton animation={animation} height={10} style={{ marginBottom: 6 }} />
                <Skeleton animation={animation} height={10} width='80%' />
              </React.Fragment>
            </CardContent>
          </Card>
        )}
      </Box>
    </Box>
  );
};

export default VerifiableTweet;
