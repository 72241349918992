import axios from 'axios';
import {
  ResponseCaptchaService,
  TextRequestInterface,
  ResponseTextService,
  TweetRequestInterface,
  ResponseTweetService,
  URLAnalysisRequestInterface,
  ResponseURLAnalysisService,
  SentenceFeedbackRequestInterface,
  ResponseURLFeedback,
} from './interfaces';

export default class BackendApi {
  static async validateCaptcha(token: string): Promise<ResponseCaptchaService> {
    try {
      const response = await axios({
        url: `${window._env_.REACT_APP_BACKEND}/recaptcha`,
        method: 'post',
        data: { token },
      });
      return { response: response.data };
    } catch (err: any) {
      return {
        error: {
          status: err.response?.status,
          message: `Error Captcha validation: ${err.response?.data?.message || err.message}`,
        },
      };
    }
  }
  static async getClaimsFromText(body: TextRequestInterface): Promise<ResponseTextService> {
    try {
      const response = await axios({
        url: `${window._env_.REACT_APP_BACKEND}/text`,
        method: 'post',
        data: body,
      });
      return { response: response.data };
    } catch (err: any) {
      return {
        error: {
          status: err.response?.status,
          message: `Error to get Claims from text: ${err.response?.data?.message || err.message}`,
        },
      };
    }
  }
  static async getClaimByTweet(body: TweetRequestInterface): Promise<ResponseTweetService> {
    try {
      const response = await axios({
        url: `${window._env_.REACT_APP_BACKEND}/twitter`,
        method: 'post',
        data: body,
      });
      return { response: response.data };
    } catch (err: any) {
      return {
        error: {
          status: err.response?.status,
          message: `Error to get Tweet by Url: ${err.response?.data?.message || err.message}`,
        },
      };
    }
  }

  static async getURLAnalysis(body: URLAnalysisRequestInterface): Promise<ResponseURLAnalysisService> {
    try {
      const response = await axios({
        url: `${window._env_.REACT_APP_BACKEND}/url-analysis`,
        method: 'post',
        data: body,
      });
      return { response: response.data };
    } catch (err: any) {
      return {
        error: {
          status: err.response?.status,
          message: `Error getting Url analysis: ${err.response?.data?.message || err.message}`,
        },
      };
    }
  }

  static async sendSentenceFeedback(body: SentenceFeedbackRequestInterface): Promise<ResponseURLFeedback> {
    try {
      const response = await axios({
        url: `${window._env_.REACT_APP_BACKEND}/url-analysis/feedback`,
        method: 'post',
        data: body,
      });
      return { response: response.data };
    } catch (err: any) {
      return {
        error: {
          status: err.response?.status,
          message: `Error to send feedback: ${err.response?.data?.message || err.message}`,
        },
      };
    }
  }
}
