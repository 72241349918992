import React from 'react';

import { X, HelpCircle, Check, AlertCircle } from 'react-feather';
import { COLORS, RATING, withOpacity } from './utils';

export type IndicatorProps = {
  theme: 'SUCCESS' | 'WARNING' | 'ALERT' | 'ERROR' | 'UNKNOWN';
  rating: RATING;
};
export default function Indicator(props: IndicatorProps) {
  const rating = props.rating;
  const secondaryColor = rating === RATING.A ? COLORS.B : COLORS[rating];
  const { color, outline, Icon } = {
    SUCCESS: {
      color: COLORS.A,
      outline: `4px solid ${withOpacity(COLORS.A, 0.3)}`,
      Icon: <Check color={'#FCFCFD'} />,
    },
    ALERT: {
      color: secondaryColor,
      outline: `4px solid ${withOpacity(secondaryColor, 0.3)}`,
      Icon: <AlertCircle color={'#FCFCFD'} />,
    },
    WARNING: {
      color: secondaryColor,
      outline: `4px solid ${withOpacity(secondaryColor, 0.3)}`,
      Icon: <AlertCircle color={'#FCFCFD'} />,
    },
    ERROR: {
      color: secondaryColor,
      outline: `4px solid ${withOpacity(secondaryColor, 0.3)}`,
      Icon: [RATING.E, RATING.F].includes(rating) ? <X color={'#FCFCFD'} /> : <AlertCircle color={'#FCFCFD'} />,
    },
    UNKNOWN: {
      color: '#98A2B3',
      outline: `4px solid ${withOpacity('#98A2B3', 0.3)}`,
      Icon: <HelpCircle color={'#FCFCFD'} />,
    },
  }[props.theme];

  return (
    <div
      style={{
        height: 32,
        width: 32,
        borderRadius: 6,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        outline,
        backgroundColor: color,
      }}
    >
      {Icon}
    </div>
  );
}
