import { Box } from '@material-ui/core';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { makeStyles, useTheme, createStyles } from '@material-ui/core/styles';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import { Check } from '@material-ui/icons';
import BubbleChartIcon from '@material-ui/icons/BubbleChart';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import PlaylistAddCheckIcon from '@material-ui/icons/PlaylistAddCheck';
import TwitterIcon from '@material-ui/icons/Twitter';
import React from 'react';

const drawerWidth = 240;

const useStyles = makeStyles(() =>
  createStyles({
    drawer: {
      width: drawerWidth,
      flexShrink: 0,
    },
    drawerPaper: {
      width: drawerWidth,
    },
    drawerHeader: {
      display: 'flex',
      alignItems: 'center',
      padding: '2px',
      justifyContent: 'flex-start',
    },
  }),
);

interface RightPanelProps {
  handleDrawerOpen: () => void;
  handleDrawerClose: () => void;
  open: boolean;
  goToPath: (path: string) => void;
}

const DrawerRight = ({ handleDrawerOpen, handleDrawerClose, open, goToPath }: RightPanelProps) => {
  const classes = useStyles();
  const theme = useTheme();

  return (
    <SwipeableDrawer
      className={classes.drawer}
      anchor='right'
      open={open}
      classes={{
        paper: classes.drawerPaper,
      }}
      onClose={handleDrawerClose}
      onOpen={handleDrawerOpen}
    >
      <Box className={classes.drawerHeader}>
        <IconButton onClick={handleDrawerClose}>{theme.direction === 'rtl' ? <ChevronLeftIcon /> : <ChevronRightIcon />}</IconButton>
      </Box>
      <Divider />
      <List>
        <ListItem button key={'content-score'} onClick={() => goToPath('/content-score')}>
          <ListItemIcon>
            <Check />
          </ListItemIcon>
          <ListItemText primary={'Content score'} />
        </ListItem>
        <ListItem button key={'text'} onClick={() => goToPath('/narratives')}>
          <ListItemIcon>
            <BubbleChartIcon />
          </ListItemIcon>
          <ListItemText primary={'Disinfo narratives'} />
        </ListItem>
        <ListItem button key={'tweet'} onClick={() => goToPath('/tweet')}>
          <ListItemIcon>
            <TwitterIcon />
          </ListItemIcon>
          <ListItemText primary={'Verifiable tweet'} />
        </ListItem>
        <ListItem button key={'text'} onClick={() => goToPath('/text')}>
          <ListItemIcon>
            <PlaylistAddCheckIcon />
          </ListItemIcon>
          <ListItemText primary={'Verifiable text'} />
        </ListItem>
      </List>
    </SwipeableDrawer>
  );
};
export default DrawerRight;
