export const withOpacity = (color: string, opacity: number): string => {
  const alphaHex = Math.floor(opacity * 255)
    .toString(16)
    .padStart(2, '0');

  return `${color}${alphaHex}`;
};
export const COLORS: Record<string, string> = {
  A: '#32D583',
  B: '#FEC84B',
  C: '#F79009',
  D: '#DC6803',
  E: '#F04438',
  F: '#F04438',
};

export enum RATING {
  A = 'A',
  B = 'B',
  C = 'C',
  D = 'D',
  E = 'E',
  F = 'F',
}
