import React from 'react';
import { useLang } from '../../contexts/lang.context';
import DummyArticle from './DummyArticle';
import s from './NewsPage.module.scss';

export type NewsPageArticleProps = {
  title: string;
  text: string;
  onClick: (response: 'HUMAN' | 'AI') => void;
};

export default function NewsPageArticle(props: NewsPageArticleProps) {
  const { t } = useLang();
  return (
    <article className={s['roa-page-article']}>
      <section
        className={s['roa-page-article-l']}
        style={{
          paddingRight: 20,
          borderRight: '2px solid #344054',
          gap: 20,
        }}
      >
        <article>
          <h2>{props.title}</h2>
          <p dangerouslySetInnerHTML={{ __html: props.text }} />
        </article>
        <div className={s['roa-page-article-buttons']}>
          <h3>{t('who')}</h3>
          <div>
            <button onClick={() => props.onClick('HUMAN')}>{t('human')}</button>
            <span>{t('or')}</span>
            <button onClick={() => props.onClick('AI')}>{t('ai')}</button>
          </div>
        </div>
      </section>
      <section className={s['roa-page-article-r']} style={{ paddingLeft: 20, overflow: 'hidden' }}>
        <DummyArticle />
        <DummyArticle />
        <DummyArticle />
        <DummyArticle />
      </section>
    </article>
  );
}
