import { Box } from '@material-ui/core';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { Color } from '@material-ui/lab/Alert';
import { History } from 'history';
import React, { RefObject } from 'react';
import { AlertsRefObject } from './Alert';
import RecaptchaWrapper from './RecaptchaWrapper';

const useStyles = makeStyles(() =>
  createStyles({
    body: {
      padding: '10px',
    },
    content: {
      padding: '25px 0px',
    },
  }),
);

interface VerifiableContentProps {
  alertRef: RefObject<AlertsRefObject>;
  history: History;
}

const VerifiableContent = ({ alertRef, history }: VerifiableContentProps) => {
  const classes = useStyles();
  const showAlert = (text: string, color: Color) => {
    alertRef.current.showAlert(text, color);
  };

  return (
    <Box id='content' className={classes.body}>
      <Box className={classes.content} />
      <RecaptchaWrapper history={history} showAlert={showAlert} />
    </Box>
  );
};

export default VerifiableContent;
