import { Box, Typography } from '@material-ui/core';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import React from 'react';
import { ConfidenceEnum } from '../api/interfaces';

const useStyles = makeStyles(() =>
  createStyles({
    claim: {
      color: 'white',
      backgroundColor: '#64b6ce',
      textAlign: 'justify',
      textAlignLast: 'center',
      padding: '5px 10px',
      flexGrow: 6,
      cursor: 'default',
      width: '60%',
      display: 'grid',
      alignItems: 'center',
      '@media (max-width: 480px)': {
        textAlign: 'center',
      },
    },
    confidence: {
      verticalAlign: 'top',
      padding: '5px 10px',
      flexGrow: 2,
      backgroundColor: '#c3c5c7',
      color: 'white',
      cursor: 'default',
      display: 'grid',
      alignItems: 'center',
      width: '22%',
      '@media (max-width: 480px)': {
        width: '40%',
      },
    },
    content: {
      margin: '1px',
      textAlign: 'center',
    },
    notClaim: {
      color: 'white',
      backgroundColor: '#e24444',
      textAlign: 'center',
      padding: '5px 10px',
      flexGrow: 6,
      cursor: 'default',
      width: '50%',
    },
    result: {
      padding: '5px',
    },
    resultBox: {
      display: 'flex',
    },
    results: {
      paddingTop: '10px',
      textAlign: 'center',
    },
    root: {
      display: 'flex',
      alignItems: 'center',
    },
  }),
);

interface VerifiableConfidenceProps {
  confidence: number;
  isVerifiable: boolean;
  text: string;
}

const VerifiableConfidence = ({ confidence, isVerifiable, text }: VerifiableConfidenceProps) => {
  const classes = useStyles();

  const getConfidence = (confidence: number) => {
    if (confidence <= 0.5) {
      return ConfidenceEnum.low;
    }
    if (confidence > 0.5 && confidence <= 0.8) {
      return ConfidenceEnum.medium;
    }
    return ConfidenceEnum.high;
  };

  return (
    <Box className={classes.content}>
      {isVerifiable != null && (
        <Box>
          <Box className={classes.resultBox}>
            <Box className={isVerifiable ? classes.claim : classes.notClaim}>
              <Box>
                {isVerifiable ? (
                  <CheckIcon style={{ color: 'white', verticalAlign: 'bottom' }} />
                ) : (
                  <CloseIcon style={{ color: 'white', verticalAlign: 'bottom' }} />
                )}
                <Typography className={classes.result} component='span'>
                  {text}
                </Typography>
              </Box>
            </Box>
            <Box className={classes.confidence}>
              <Tooltip title='Algorithm confidence in prediction' arrow>
                <Typography component='span'>Confidence: {getConfidence(confidence)}</Typography>
              </Tooltip>
            </Box>
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default VerifiableConfidence;
