import { VerifiableState, VerifiablectionTypes, SET_TOKEN } from '../types/verifiableTypes';

export const defaultState: VerifiableState = {
  token: null,
};

export function verifiable(state = defaultState, action: VerifiablectionTypes) {
  switch (action.type) {
    case SET_TOKEN:
      return {
        ...state,
        token: action.token,
      };
    default:
      return {
        ...state,
      };
  }
}
